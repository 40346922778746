import { React, useEffect, useState } from "react";
import '../styles/Result.css'
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import MyPDFViewer from "../utils/pdf";
import Header from "../components/Header";
import Modal from "../components/IOFModal"; // Importe o componente Modal
import { useCalculoTabela } from '../utils/Generator';
import { FaCircleCheck } from "react-icons/fa6";
import { AiFillCloseCircle } from "react-icons/ai"
import { parseDateHyphen } from "../utils/Tools";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Result = () => {
    const [appear, setAppear] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [rentabilidade, setRentabilidade] = useState(false)
    const [isExpanded, setIsExpanded] = useState(false);

    const handleExpand = () => {
        setIsExpanded(!isExpanded);
    };
    useEffect(() => {
        handleExpand()

    }, [])
    const allInfoArray1 = JSON.parse(localStorage.getItem('allInfoArray'));
    const formValues = JSON.parse(localStorage.getItem('formValues'));
    const sacData2 = useSelector((state) => state.sacData.sacData)
    const calcData2 = useSelector((state) => state.calcData.calcData) || []
    let calcData = calcData2 ? calcData2 : null
    let sacData = sacData2 ? sacData2 : null

    useEffect(() => {
        if (calcData === null && sacData === null) {
            window.alert('Cálculo não realizado!')
            navigate('/home')
            return
        }
    },);
    const allInfoArray2 = useSelector((state) => state.finalTotal.finalTotal)
    const allInfoArray = allInfoArray1 || allInfoArray2
    useEffect(() => {
        if (allInfoArray.length < 1) {
            window.alert('Cálculo não realizado!')
            navigate('/home')
            return
        }
    })
    const navigate = useNavigate();
    const userTokenString = JSON.parse(localStorage.getItem('user'));
    const valorInserido = JSON.parse(localStorage.getItem('valorInserido'));
    const now = (formValues)&&formValues.dataEmissao? parseDateHyphen(formValues.dataEmissao): new Date();


    const convertToNumber = (value) => {
        const cleanedValue = value.replace(/[^0-9,-]+/g, '');

        return parseFloat(cleanedValue.replace(',', '.'));
    }

    const handleAmpliarRentabilidade = () => {
        const valorAtual = convertToNumber(allInfoArray[14]);
        const valorParaSomar = convertToNumber(allInfoArray[13]);
        setModalOpen(true);
    }
    const { calcularTabela } = useCalculoTabela();
    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleConfirmModal = () => {
        // Puxe o valorInserido do localStorage novamente para garantir que você tenha o valor atualizado
        const valorInserido = JSON.parse(localStorage.getItem('valorInserido'));
        const novoTotal = valorInserido + convertToNumber(allInfoArray[6]);
        const vencimentoObj = new Date(formValues.vencimento);
        const dia = vencimentoObj.getDate();
        const mes = vencimentoObj.getMonth();
        const ano = vencimentoObj.getFullYear();
        calcularTabela(30, novoTotal.toFixed(2), parseFloat(formValues.juros).toFixed(2), parseFloat(formValues.parcelas), parseFloat(formValues.carencia), parseFloat(formValues.capital).toFixed(2), parseFloat(formValues.taxaEmissao).toFixed(2), dia, mes, ano, parseFloat(formValues.emissaoCCB).toFixed(2), 0.00, 0.00);
        setModalOpen(false);
        setRentabilidade(true);
    };
    const convertMonetaryValueToNumber = (monetaryValue) => {
        // Verifica se monetaryValue é uma string
        if (typeof monetaryValue !== 'string') {

            return NaN;
        }

        // Remove os símbolos de moeda (R$), vírgulas e espaços
        const cleanedValue = monetaryValue.replace(/[^0-9]+/g, '');

        // Converte para um número inteiro
        return parseInt(cleanedValue, 10);
    };

    const isValidValue = (value) => value !== undefined && value !== null && value !== '';

    const handleAppear = () => {
        if (appear === false) {
            setAppear(true)
        }
        else (setAppear(false))
    }
    return (
        <body className="resultBody">
            <div className="fixed">
                <Header selected={'result'} />
            </div>
            <main className="resultMain">
                <div className="ahead">
                    <div className="div1">
                        <h1>Cenário Desenvolvido</h1>
                    </div>
                </div>
                <div className="many">
                    <div className="infoClass1">
                        <div className={`horizontal-align ${isExpanded ? 'expanded' : ''}`}>
                            <h3 className="h3Info">Valor da Operação:</h3>
                            <p className="textInfo">{allInfoArray[5] || '0,00'}</p>
                        </div>
                        <div className={`horizontal-align ${isExpanded ? 'expanded' : ''}`}>
                            <h3 className="h3Info">Total Pago pelo Cliente:</h3>
                            <div className="textInfo">{allInfoArray[12] || '0,00'}</div>
                        </div>
                        <div className={`horizontal-align ${isExpanded ? 'expanded' : ''}`}>
                            <h3 className="h3Info">Custo Financeiro:</h3>
                            <div className="textInfo">{allInfoArray[11] || '0,00'}</div>
                        </div>

                    </div>
                    <div className="infoClass">
                        <div className={`horizontal-align ${isExpanded ? 'expanded' : ''}`}>
                            <h3 className="h3Info">SPREAD:</h3>
                            <p className="textInfo">{allInfoArray[16] || '0,00'}</p>
                        </div>
                        <div className={`horizontal-align ${isExpanded ? 'expanded' : ''}`}>
                            <h3 className="h3Info">Ganho Real (Spread - Custo Financeiro):</h3>
                            <p className="textInfo">{allInfoArray[18] || '0,00'}</p>
                        </div>
                        <div className={`horizontal-align ${isExpanded ? 'expanded' : ''}`}>
                            <h3 className="h3Info">Margem:</h3>
                            <p className="textInfo">{allInfoArray[1] || '0,00'} %</p>
                        </div>

                    </div>
                    <div className="infoClass1">
                        {rentabilidade ?
                            <div className={`horizontal-align ${isExpanded ? 'expanded' : ''}`}>
                                <h3 className="okInfo">Economia convertida em ganho!</h3>
                                <FaCircleCheck color="green" style={{ alignSelf: 'center', marginLeft: '3px' }} />
                            </div>
                            : <div className={`horizontal-align ${isExpanded ? 'expanded' : ''}`}>
                                <h3 className="h3Info">Economia com Impostos:</h3>
                                <div className="textInfo">{allInfoArray[14]}</div>
                            </div>}
                        <div className={`horizontal-align ${isExpanded ? 'expanded' : ''}`}>
                            <h3 className="h3Info">Taxa de Emissão Nota Comercial:</h3>
                            <div className={`textInfo ${isValidValue(allInfoArray[2]) && isValidValue(allInfoArray[15]) && convertMonetaryValueToNumber(allInfoArray[2]) < convertMonetaryValueToNumber(allInfoArray[15]) ? 'cheaper' : ''}`}>
                                {isValidValue(allInfoArray[2]) ? allInfoArray[2] : 'N/A'}
                            </div>
                        </div>
                        <div className={`horizontal-align ${isExpanded ? 'expanded' : ''}`}>
                            <h3 className="h3Info">Quanto custaria emitir CCB:</h3>
                            <div className={`textInfo ${isValidValue(allInfoArray[15]) && isValidValue(allInfoArray[2]) && convertMonetaryValueToNumber(allInfoArray[15]) < convertMonetaryValueToNumber(allInfoArray[2]) ? 'cheaper' : ''}`}>
                                {isValidValue(allInfoArray[15]) ? allInfoArray[15] : 'N/A'}
                            </div>
                        </div>

                    </div>
                </div>
                <div className="rentabilidade">
                    {rentabilidade || calcData2.length < 1 ?
                        <div></div>
                        : <button onClick={handleAmpliarRentabilidade} className="button12">
                            Deseja ampliar sua Rentabilidade?
                        </button>
                    }
                    <button onClick={handleAppear} className="button12">Exportar Cenário</button>
                    <Modal
                        isOpen={modalOpen}
                        onClose={handleCloseModal}
                        onConfirm={handleConfirmModal}
                        valorAtual={allInfoArray[16]}
                        valorParaSomar={allInfoArray[14]}
                    />
                    {appear ? (
                        <div className="pdf-container active">
                            <div onClick={handleAppear} >
                                <AiFillCloseCircle size={40} className="close-button1" color="#f83939" />
                            </div>
                            <MyPDFViewer />
                        </div>
                    ) : null}
                </div>
                <div className="manyTable">
                    <div className="divTable">
                        <table>
                            <thead>
                                <tr>
                                    <th>Período</th>
                                    <th>Vencimento</th>
                                    <th>Valor da Parcela</th>
                                    <th>Amortização</th>
                                    <th>Juros</th>
                                    <th>Saldo Devedor</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="centralizado">0</td>
                                    <td className="centralizado">{now.getDate().toString().padStart(2, '0')}/{(now.getMonth() + 1).toString().padStart(2, '0')}/{now.getFullYear()}</td>
                                    <td className="direita">0</td>
                                    <td className="direita">0</td>
                                    <td className="direita">0</td>
                                    <td className="direita">{allInfoArray[13]}</td>
                                </tr>
                                {calcData ? calcData.map((data, index) => (
                                    <tr key={index}>
                                        <td className="centralizado">{data[0]}</td>
                                        <td className="centralizado">
                                            {data[1] < 10 ? `0${data[1]}` : data[1]}/{data[2] === '00' ? 12 : data[2]}/{data[3]}
                                        </td>
                                        <td className="direita">
                                            {data[4].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                        </td>
                                        <td className="direita">
                                            {data[5].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                        </td>
                                        <td className="direita">
                                            {data[6].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                        </td>
                                        <td className="direita">
                                            {data[7].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                        </td>
                                    </tr>
                                )) : null}
                                {sacData ? sacData.map((data, index) => (
                                    <tr key={index}>
                                        <td className="centralizado">{data[0]}</td>
                                        <td className="centralizado">
                                            {data[1] < 10 ? `0${data[1]}` : data[1]}/{data[2] === '00' ? 12 : data[2]}/{data[3]}
                                        </td>
                                        <td className="direita">
                                            {data[4].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                        </td>
                                        <td className="direita">
                                            {data[5].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                        </td>
                                        <td className="direita">
                                            {data[6].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                        </td>
                                        <td className="direita">
                                            {data[7].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                        </td>
                                    </tr>
                                )) : null}
                            </tbody>
                        </table>
                    </div>
                    {/* <div onClick={() => { navigate('/irregular') }}>
                        Irregular
                    </div> */}
                </div>
            </main>
        </body>
    );
};

export default Result;
