import { useState } from "react";
import Header from "../components/Header"
import { formatDocument } from "../utils/Tools";
import { FaPlus } from "react-icons/fa6";
import ConvertApi from 'convertapi-js'
import { IoIosCloudCircle } from "react-icons/io";
import '../styles/Garantias.css';

let convertApi = ConvertApi.auth('jcypIJpRZdtrhHpC');


const Garantias = () => {
    const [garantia, setGarantia] = useState(false);
    const [gTipo, setGTipo] = useState('');
    const [gQuantidade, setGQuantidade] = useState('1');
    const [gProprietario, setGProprietario] = useState('');
    const [gDocumento, setGDocumento] = useState('');
    const [gNatureza, setGNatureza] = useState('Pessoa Física');
    const [gDescricao, setGDescricao] = useState('');
    const [documentFields, setDocumentFields] = useState([]);
    const [loading, setLoading] = useState(false);
    const [img, setImg] = useState([]);

    const handleSocios = async (e) => {
        setLoading(true);
        const selectedFile = e.target.files[0];
        const params = convertApi.createParams();
        params.add('File', selectedFile);
        try {
            const result = await convertApi.convert('pdf', 'jpg', params);
            const imageUrls = result.dto.Files.map((file) => file.Url);
            setImg((prevImg) => [...prevImg, ...imageUrls]);
            localStorage.setItem('socios', JSON.stringify([...img, ...imageUrls]));
            setLoading(false);
        } catch (error) {
            console.error('Erro ao converter arquivo:', error);
            setLoading(false);
        }
    }

    const saveGarantiaToLocalStorage = () => {
        const garantiaData = {
            tipo: gTipo,
            natureza: gNatureza,
            descricao: gDescricao,
            proprietario: gProprietario,
            documento: gDocumento,
            quantidade: gQuantidade,
            documentos: documentFields,
        };

        // Converta o objeto para uma string JSON
        const garantiaDataString = JSON.stringify(garantiaData);

        // Salve a string JSON no localStorage
        localStorage.setItem('garantiaData', garantiaDataString);
        localStorage.setItem('garantia', true);
        window.alert('Garantia salva com sucesso!');

        setGarantia(false);
    };

    const removeLastDocumentField = () => {
        const updatedFields = [...documentFields];
        updatedFields.pop(); // Remove o último elemento do array
        setDocumentFields(updatedFields);
    };

    const addDocumentField = () => {
        setDocumentFields([...documentFields, ""]);
    };
    return (
        <div className="garantiasBody">
            <div className="fixed">
                <Header selected="garantias" />
            </div>
            <form className="garantiasDiv">
                <div className="divGarantia1">
                    <h2 style={{ color: '#1a2f67', fontSize: '1.2rem' }}>Insira as informações de garantia</h2>

                </div>
                <div className="divTitleDoc11">
                    <h3 className="confirmationMessage1">Informações de Garantia</h3>
                </div>
                <div className="divInputsDoc">
                    <label htmlFor="" className="labelInputsDoc">
                        Tipo de Garantia(s):
                    </label>
                    <select
                        className='selectDoc1'
                        value={gTipo}
                        onChange={(e) => setGTipo(e.target.value)}
                    >
                        <option value="">Escolha as garantias</option>
                        <option value="Veículo">Veículo</option>
                        <option value="Imóvel">Imóvel</option>
                        <option value="Maquinário">Maquinário</option>
                        <option value="Duplicatas">Duplicatas</option>
                        <option value="Recebíveis - Cartão de Crédito">Recebíveis - Cartão de Crédito</option>
                        <option value="Outros">Outros</option>
                    </select>
                </div>
                <div className="divInputsDoc">
                    <label htmlFor="" className="labelInputsDoc">
                        Quantidade de bens:
                    </label>
                    <select
                        className='selectDoc1'
                        value={gQuantidade}
                        onChange={(e) => setGQuantidade(e.target.value)}
                    >
                        {[...Array(10)].map((_, index) => (
                            <option key={index} value={index + 1}>
                                {index + 1}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="divInputsDoc">
                    <label htmlFor="" className="labelInputsDoc">
                        Descrição da(s) Garantia(s):
                    </label>
                    <textarea
                        rows={4}  // Adjust the number of rows as needed
                        className='inputTextarea' // Apply the new CSS class here
                        placeholder="Ex: Ford Fusion 2017 Placa DEN1E15"
                        value={gDescricao}
                        onChange={(e) => setGDescricao(e.target.value)}
                    />
                </div>
                <div className="divInputsDoc">
                    <label htmlFor="" className="labelInputsDoc">
                        Proprietário Garantidor:
                    </label>
                    <input
                        type="text"
                        className='newInput'
                        placeholder="Nome do Proprietário Garantidor"
                        value={gProprietario}
                        onChange={(e) => setGProprietario(e.target.value)} />
                </div>
                <div className="divInputsDoc">
                    <label htmlFor="" className="labelInputsDoc">
                        Natureza do Garantidor:
                    </label>
                    <select
                        className='selectDoc1'
                        value={gNatureza}
                        onChange={(e) => setGNatureza(e.target.value)}
                    >
                        <option value="Pessoa Física">Pessoa Física</option>
                        <option value="Pessoa Jurídica">Pessoa Jurídica</option>
                    </select>
                </div>
                <div className="divInputsDoc">
                    <label htmlFor="" className="labelInputsDoc">
                        CPF/CNPJ do Garantidor:
                    </label>
                    <input
                        type="text"
                        className='newInput'
                        placeholder=" CPF/CNPJ do Garantidor"
                        value={formatDocument(gDocumento)}
                        onChange={(e) => setGDocumento(e.target.value)}
                        maxLength={18} />
                </div>
                <div className="divInputsDoc3">
                    <div className="aboveButton" onClick={addDocumentField}>
                        <FaPlus size={15} /> Adicionar Documentos de Garantia (PDF)
                    </div>
                    {loading ? (
                        <div className="divTitleDoc">Inserindo Arquivos Na Minuta</div>
                    ) : null}
                    {documentFields.map((field, index) => (
                        <div key={index} className="divInputsDoc1">
                            <label className="fileLabel">
                                <input
                                    type="file"
                                    name="files"
                                    className="file-input"
                                    accept=".pdf, .doc, .docx"
                                    onChange={(e) => handleSocios(e)}
                                    style={{ display: 'none' }}
                                />
                                <IoIosCloudCircle size={25} style={{ marginRight: '40px' }} />
                                <span>Documentos de Garantia {index + 1}</span>
                            </label>
                        </div>
                    ))}
                    {documentFields && documentFields.length > 0 && <button type="button" onClick={removeLastDocumentField}>Remover</button>}
                </div>
                <div className="divGarantia" style={{ display: 'flex', width: '100%', justifyContent: 'end' }}>

                    <div onClick={() => { saveGarantiaToLocalStorage() }} className="buttonSendGarantia">
                        Salvar Informações de Garantia
                    </div>
                </div>
            </form></div>
    )
}

export default Garantias